
<template>
  <div class="informationList">
    <div class="top">
      <el-scrollbar>
        <div class="li_box">
          <span
            v-for="(rl, i) in tabs"
            :key="i"
            :class="contactsIndex == rl.Id ? 'active' : ''"
            @click="contactsClick(rl, i)"
            >{{ rl.CategoryName }}
          </span>
        </div>
      </el-scrollbar>
    </div>
    <div class="cont" v-if="list.length > 0">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="search"
      >
        <el-row
          v-for="(li, i) in list"
          :key="i"
          type="flex"
          justify="space-between"
          align="top"
          @click.native="handleTo(li)"
        >
          <el-col>
            <div class="topTitle">
              <div :class="[li.IsDeal == false ?'title':'']">
                  <span>{{ li.Title }}</span>
              </div>
              <div class="time">{{li.AddTime | dataYMD }}</div>
            </div>
            <div class="det">
              <span>{{ li.ContentStr }}</span>
            </div>
          </el-col>
        </el-row>
      </van-list>
    </div>
    <div v-else>
      <el-row>
        <el-col :span="24">
          <no-data></no-data>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {
  queryNotice
} from "@/api/personal";
import noData from "@/components/noData";


export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 5,
      total: 0,
      contactsIndex: 0,
      type: 0,
      tabs: [],
      loading: false,
      finished: false,
      route: {},
      userId:0,
    };
  },
  components: {
    noData,
  },
  created() {},
  mounted() {
    this.route = this.$route.query;
    this.userId = this.route.UserId;
    this.search();
  },
  methods: {
    async search() {
      const res = await queryNotice("?pageIndex="+this.page+"&&pageSize="+this.pageSize+"&&StudentId="+this.userId);
      if (res.success === true) {
        this.list = this.list.concat(res.response.data);
        this.page++;
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= res.response.dataCount) {
          this.finished = true;
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    handleTo(obj) {
      let path = "/H5/noticeList/details";
        let query = {
          detail: JSON.stringify(obj),
        };
        this.$router.push({ path, query });
        // this.$router.push("/H5/noticeList/details");
    },
    //浏览量自动+1
    async postUpdateNewsReadCount(id) {
      let parm = "?Id=" + id;
      const res = await updateNewsReadCount(parm);
    },
    
  },
};
</script>

<style lang="less" scoped>
.informationList {
  min-height: 100vh;
  background-color: #fff;
  .top {
    padding: 0 15px;
    background-color: #fff;
    color: #5b5b5b;
    .li_box {
      display: flex;
    }
    span {
      display: inline-block;
      min-width: 70px;
      text-align: center;
      margin-right: 10px;
      line-height: 60px;
      cursor: pointer;
    }
    .active {
      color: #1288f4;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 3px;
        background-color: #1288f4;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
  .cont {
    // padding: 0 15px;
    .el-row {
      margin: 10px 0;
      padding-bottom: 10px;
    border-bottom: 1px solid #eee;
      .el-col {
        // &:first-child {
        //   width: 146px;
        //   flex: 0 0 146px;
        //   margin-right: 15px;
        //   img {
        //     width: 100%;
        //     height: 90px;
        //     border-radius: 6px;
        //   }
        // }
        .topTitle {
          padding: 0 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 10px;
          .title{
            ::before{
              content: " ";
              display: inline-block;
              width: 8px;
              height: 8px;
              background-color: red;
              border-radius: 50px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
          span{
          display: -webkit-box;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          overflow: hidden;
          }
          .time {
            font-weight: normal;
            width: 75px;
            flex: 0 0 75px;
            font-size: 13px;
            color: #999999;
          }
        }
        .det {
          font-size: 13px;
          color: #999999;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding: 0 15px;
        }
        // &:last-child {
        //   display: flex;
        //   align-content: space-between;
        //   flex-wrap: wrap;
        //   .til {
        //     display: -webkit-box;
        //     -webkit-line-clamp: 2;
        //     text-overflow: ellipsis;
        //     -webkit-box-orient: vertical;
        //     overflow: hidden;
        //     line-height: 1.5;
        //     font-size: 15px;
        //     font-weight: bold;
        //     word-break: break-all;
        //     width: 100%;
        //   }
        //   .time {
        //     font-size: 13px;
        //     color: #999999;
        //     line-height: 1.5;
        //     display: flex;
        //     justify-content: space-between;
        //     width: 100%;
        //     // span{display: inline-block;width: 50%;}
        //   }
        // }
      }
    }
  }
}

/deep/.el-scrollbar {
  .el-scrollbar__bar {
    display: none;
  }
}
</style>